import React, {Dispatch, FC, ReactNode, SetStateAction} from "react"
import {FormattedMessage} from "react-intl";
import Link from "next/link";
import dynamic from "next/dynamic";
import {useSelector} from "react-redux";
import {IState} from "../../../types/state";
import {cartItemsType} from "./types";
import { propsTypeForDropDown as IProps } from "./types"

const Currency = dynamic(() => import('../../shared/Currency'))


const DropDown: FC<IProps> = (
    {
        cart,
        items,
        setOpen,
        isCheckAllow
    }
) => {
    //Redux
    const customer = useSelector((state: IState) => state.customer);
    const coreConfigs = useSelector((state: IState) => state.general.coreConfigs);

    return (
        <div className="dropcart">
            <div
                className={`${cart.items.length > 3
                    ? "dropcart__products-list_scroll"
                    : "dropcart__products-list"
                }`}
            >
                {items}
            </div>

            <div className="dropcart__totals">
                <table>
                    <tbody>
                        <tr>
                            <th>
                                <FormattedMessage id="total" defaultMessage="Total"/>{" "}
                            </th>
                            <td>
                                <Currency value={cart.total}/>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="dropcart__buttons">
                <Link
                    href="/cart">
                    <a
                        className="btn btn-orange rounded-pills"
                        onClick={() => setOpen((prev) => !prev)}
                    >
                        <FormattedMessage id="cart" defaultMessage="Cart"/>
                    </a>
                </Link>
                <Link
                    href={coreConfigs.catalog_products_guest_checkout_allow_guest_checkout == "0" && customer.token === "" ? "" : "/checkout"}>
                    <a
                        className="btn btn-orange rounded-pills dropcart__buttons-link"
                        onClick={isCheckAllow}
                    >
                        <FormattedMessage id="checkout" defaultMessage="checkout"/>
                    </a>
                </Link>
            </div>
        </div>
    )
}

export default DropDown
import React from "react";
import {FormattedMessage} from "react-intl";
import { FailSvg } from "../../../svg"

const NotAvailable = () => (
    <span className="d-flex faild-toast-fms">
        <FailSvg/>
        <FormattedMessage
            id="sign-or-register"
            defaultMessage="This product is not available"
        />
    </span>
)

export default NotAvailable;

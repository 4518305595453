import React, {FC, useMemo} from "react"
import dynamic from "next/dynamic";

import classNames from "classnames";
import {cartRemoveItem} from "../../../store/cart"
import {useDispatch, useSelector} from "react-redux";
import { Cross10Svg } from "../../../svg";
import {IState} from "../../../types/state";
import {cartItemsType} from "./types";
// @ts-ignore
const AsyncAction = dynamic(() => import('../../shared/AsyncAction'))

const RemoveButton: FC<{ cartItemId: number | null, item: cartItemsType }> = (
    {
        cartItemId,
        item
    }
) => {
    const dispatch = useDispatch()
    const cartToken = useSelector((state: IState) => state.cartToken);
    const customer = useSelector((state: IState) => state.customer);

    const removeButton = useMemo(() => {
        const ButtonRemove = ({ run, loading }: { run: () => void; loading: boolean }) => {
            const classes = classNames(
                "dropcart__product-remove btn-light btn-sm btn-svg-icon",
                {
                    "btn-loading": loading,
                }
            );
            return (
                <button type="button" onClick={run} className={classes}>
                    <Cross10Svg />
                </button>
            );
        };

        ButtonRemove.displayName = "ButtonRemove";

        return ButtonRemove;
    }, [])
    return (
        <AsyncAction
            action={() => dispatch(cartRemoveItem(cartItemId, item, cartToken, customer))}
            render={removeButton}
        />
    )
}

export default RemoveButton;
// react
import React, {useCallback, useEffect, useMemo, useState} from "react";
// third-party
import { useSelector } from "react-redux";
import Link from "next/link";
// SVG
import {HotelCart} from "../../svg";

import {url} from "../../services/utils";
import {FormattedMessage} from "react-intl";
import dynamic from 'next/dynamic'
import {useToast} from "../../atoms/Toast/useToast";
import {IState} from "../../types/state";
import NotAvailable from "./IndicatorCart/NotAvailable";
import {cardItemId, cartItemsType} from "./IndicatorCart/types";
import ProductImage from "./IndicatorCart/ProductImage";
import RemoveButton from "./IndicatorCart/RemoveButton";
import DropDown from "./IndicatorCart/DropDown";

const Indicator = dynamic(() => import('./Indicator'))
const Prices = dynamic(() => import('../shared/Prices'))


const IndicatorCart = () => {
    // // Redux
    const toast = useToast();
    const customer = useSelector((state: IState) => state.customer);
    const cartData = useSelector((state: IState) => state.cart);
    const locale = useSelector((state: IState) => state.locale.code);
    const coreConfigs = useSelector((state: IState) => state.general.coreConfigs);
    // State
    const [cart, setCart] = useState<any>(cartData);
    const [open, setOpen] = useState<boolean>(false);
    useEffect(() => {
        setCart(cartData)
    }, [cartData, locale]);

    const isCheckAllow = useCallback(() => {
        setOpen(!open)
        const { catalog_products_guest_checkout_allow_guest_checkout } = coreConfigs
        if (catalog_products_guest_checkout_allow_guest_checkout == "0" && customer.token === "") {
            toast.open(<NotAvailable />);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const items = cart?.items.map((item: cartItemsType, I: number) => {
        let cartItemId: number | null = null;
        const itemIndex = cart?.cartItems?.findIndex(
            (cartItem: cardItemId) => cartItem.productID === item.product.id
        );
        if (itemIndex !== -1) {
            cartItemId = cart.cartItems?.[itemIndex].cartItemId;
        }

        const product = cart.items[I].product;

        return (
            <div key={item.id} className="dropcart__product">
                <ProductImage
                    length={item.product.product_images?.length}
                    item={item}
                />
                <div className="dropcart__product-info">
                    <div className="dropcart__product-name">
                        <Link href={url.product(item.product)}>
                            <a onClick={() => setOpen(!open)}>
                                {
                                    item.product.name
                                        ? item.product.name
                                        //@ts-ignore
                                        : item.product.product_flat?.[0]?.name || ""
                                }
                            </a>

                        </Link>
                    </div>
                    <div className="dropcart__product-meta">
                        <span className="dropcart__product-quantity">{item.quantity}</span>
                        {" × "}
                        <span className="dropcart__product-price">
                            <Prices product={product} cart={true}/>
                        </span>
                    </div>
                </div>
                <RemoveButton
                    cartItemId={cartItemId}
                    item={item}
                />
            </div>
        );
    });
    const cartDropDown = useMemo(() => {
        if (cart && Object.keys(cart).length > 0 && cart.quantity) {
            return <DropDown
                isCheckAllow={isCheckAllow}
                setOpen={setOpen}
                cart={cart}
                items={items}
            />
        }
        return (
            <div className="dropcart">
                <div className={"dropcart__empty"}>
                    <FormattedMessage
                        id="cart_empty"
                        defaultMessage="Your cart is empty!"
                    />
                </div>
            </div>
        )
    }, [cart, isCheckAllow, items])

    const handleCheck = (bool: boolean): void => {
        setOpen(bool);
    };

    return (
        <Indicator
            className="cart-icon"
            url="/cart"
            func={handleCheck as (e: boolean | undefined) => void}
            openEd={open}
            open={open}
            dropdown={cartDropDown}
            value={cart ? cart.quantity : 0}
            icon={<HotelCart/>}
            title={<FormattedMessage id="cart" defaultMessage="Cart"/>}
        />
    );
}


export default IndicatorCart;

import React, {useCallback, FC} from "react";
import Link from "next/link";
import {url} from "../../../services/utils";
import {LazyLoadImage} from "react-lazy-load-image-component";
import {propsTypeForProductImage as IProps} from "./types"

const ProductImage: FC<IProps> = ({ length, item }) => {
    const sourceUrl = useCallback(() => {
        if (length) {
            return item.product.product_images[0].path && `/cache/medium/${item.product.product_images[0].path}`
        }
        return `${item.product.base_image.small_image_url}`
    }, [length])
    return (
        <div className="product-image dropcart__product-image">
            <Link
                href={url.product(item.product)}
                className="product-image__body"
            >
                <a>
                    <LazyLoadImage
                        className="product-image__img product-small-img"
                        height={56}
                        width={56}
                        alt={""}
                        src={sourceUrl()}
                    />
                </a>

            </Link>
        </div>
    )
}

export default ProductImage;